// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';
import '@splidejs/splide/css/skyblue';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Maps
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';
import LazyHeader from '@components/LazyHeader.vue';
import UseElementVisibility from '@components/UseElementVisibility.vue';

// ================================================
// SUI Components
// ================================================

import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiPopover: Popover,
        HuiPopoverButton: PopoverButton,
        HuiPopoverPanel: PopoverPanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        GoogleMapEmbed,
        LazyHeader,
        UseElementVisibility,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
    },

    data() {
        return {
            navActive: false,
            siteSearch: false,
            showHeader: true,
            stickyHeader: false,
        };
    },

    mounted() {
        const filterMap = document.querySelector('#js-mapfilter');
        const mapForm = document.querySelector('#mapfilter-form');

        if (mapForm) {
            const replaceState = (params) => {
                if (params === '') {
                    window.location.replace(
                        `${location.protocol}//${location.host}${location.pathname}`,
                    );
                    return;
                }

                window.location.replace(
                    `${location.protocol}//${location.host}${location.pathname}?${params}`,
                );
            };

            filterMap.addEventListener('change', (e) => {
                const searchParams = new URLSearchParams(
                    window.location.search,
                );

                if (e.target.value == '') {
                    searchParams.delete('type');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('type', e.target.value);

                replaceState(searchParams.toString());

                mapForm.scrollIntoView({
                    behavior: 'smooth',
                });
            });
        }
    },
}).mount('#app');
