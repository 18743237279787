<template>
    <div ref="mapEl"></div>
</template>

<script setup>
import { Loader } from '@googlemaps/js-api-loader';
import { defineProps, onMounted, ref } from 'vue';

const props = defineProps({
    center: {
        type: Object,
        required: true,
    },
    markers: {
        type: Array,
        default: () => {
            return [];
        },
    },
    zoom: {
        type: Number,
        required: true,
    },
    apiKey: {
        type: String,
        default: 'AIzaSyCv2HOM1E369ssDDg5iGfPVHmF0fnhv5zY',
    },
});
const mapEl = ref(null);

onMounted(() => {
    // Setup the Google Maps JS API loader
    const loader = new Loader({
        apiKey: props.apiKey,
        version: 'weekly',
    });
    // Load the Google Maps JS API then initialise a new a map
    loader.load().then(() => {
        const map = new google.maps.Map(mapEl.value, {
            center: {
                lat: parseFloat(props.center.lat),
                lng: parseFloat(props.center.lng),
            },
            zoom: props.zoom,
        });
        if (!props.markers.length) {
            return;
        }
        props.markers.forEach((marker) => {
            let markerOptions = {
                position: {
                    lat: parseFloat(marker.lat),
                    lng: parseFloat(marker.lng),
                },
                map,
            };
            markerOptions = {
                ...markerOptions,
                icon: marker.icon,
                title: marker.title,
            };
            const mapMarker = new google.maps.Marker(markerOptions);
        });
        // Markers Pan on ASV schools list
        const mapPan = document.querySelectorAll('.js-map-pan');

        if (mapPan.length) {
            mapPan.forEach((s) => {
                s.addEventListener('click', (e) => {
                    const panPoint = new google.maps.LatLng(s.dataset.maplat, s.dataset.maplng);
                    map.panTo(panPoint);
                    map.setZoom(16);
                });
            });
        }
    });
});
</script>
