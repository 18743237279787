<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core';
import { ref } from 'vue';

const el = ref(null);
const isVisible = useElementVisibility(el);
</script>

<template>
    <div
        ref="el"
        class="transition duration-1000 ease-in-out"
        :class="isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'"
    >
        <slot></slot>
    </div>
</template>
